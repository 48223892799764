export default {
    grid: {
        top: 20,
        left: 0,
        right: 10,
        bottom: 10,
        containLabel: true
    },
    xAxis: {
        type: 'category',
        axisLabel: {
            fontSize: 12,
        },
        splitLine: {
            show: false,
        },
        axisLine: {
            lineStyle: {
                width: 2,
            }
        },
        data: []
    },
    yAxis: {
        splitLine: {
            show: true,
        },
        axisLine: {
            show: true,
            lineStyle: {
                width: 2,
            }
        },
        axisLabel: {
            fontSize: 14,
            fontWeight: 600,
        },
        minInterval: 1
    },
    series: [
        {
            data: [],
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,
            itemStyle: {
                borderWidth: 4,
            },
            lineStyle: {
                width: 2
            }
        }
    ]
};
