<template>
  <a-descriptions bordered title="基本信息" size="middle" :column="2">
    <a-descriptions-item
      :label="item.label"
      v-for="item in infoItems"
      :key="item.key">{{item.value}}</a-descriptions-item>
  </a-descriptions>
</template>

<script>
    import {getAssListStatusText, getAssTypeName, getAssVisibleType} from "../common/hr/ass";

    export default {
        name: "AssListInfo",
        props: {
            info: Object
        },
        computed: {
            infoItems() {
                let res = [];
                const info = this.info;
                if(info) {
                    const program = info.program
                    res = [
                        {label: '评价编号', value: info.num || '--', key: "num"},
                        {label: '关联方案', value: program?.name || '该方案已删除', key: "program"},
                        {label: '开始时间', value: info.begin_time, key: "begin_time"},
                        {label: '结束时间', value: info.end_time, key: "end_time"},
                        {label: '评价状态', value: getAssListStatusText(info.status), key: "status"},
                        {label: '评价对象', value: getAssTypeName(info.program_type), key: "program_type"},
                        {label: '评价完成情况', value: info.progress ? `${info.progress.done}/${info.progress.all}` : "--", key: "result"},
                        {label: '评价结果可见性', value: getAssVisibleType(program?.is_visible), key: "is_visible"},
                    ]
                }
                return res;
            }
        }
    }
</script>

<style scoped>

</style>
