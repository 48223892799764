<template>
  <div class="staff-brief" :class="{'staff-info-reversed': reversed}" v-if="info">
    <div class="staff-info">
      <div class="staff-name">{{ info.fm || info.name}}</div>
      <a-row :gutter="16">
        <a-col
          :span="itemSpan"
          v-for="item in infoItems"
          :key="item.key">
          <div class="staff-brief-item">{{item.label}}: {{item.value || '未录入'}}</div>
        </a-col>
      </a-row>
    </div>
    <div class="staff-chart" v-if="false"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import {dealStaff, getSex, getStaffRadar} from "@/common/hr/book";
let chartOption  = {
  radar: {
    radius: '50%',
    indicator: []
  },
  series: [
    {
      name: '僧众',
      type: 'radar',
      data: [
        {
          value: [80, 80, 80, 80, 80, 80],
          name: '能力值'
        }
      ]
    }
  ]
};
export default {
  name: "staff-brief",
  props: {
    info: Object,
    reversed: Boolean,
    itemSpan: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      chart: null
    }
  },
  computed: {
    infoItems() {
      let res = [];
      const info = this.info;
      dealStaff(info);
      if(info) {
        res = [
          {label: '身份', value: info.typeName, key: "type"},
          {label: '部门', value: info.dept, key: "dept"},
        ]
        if(info.type == 1) {
          res.push(
            {label: '法号', value: info.fh, key: "fh"},
          )
        } else {
          res.push(
            {label: '入职日期', value: info.entry_date, key: "entry_date"},
            {label: '性别', value: getSex(info.sex), key: "sex"},
          )
        }
      }
      return res;
    }
  },
  // watch: {
  //   info() {
  //     this.initChart();
  //   }
  // },
  // mounted() {
  //   this.initChart();
  // },
  methods: {
    initChart() {
      const info = this.info;
      if(!this.chart) {
        this.chart = echarts.init(this.$el.querySelector(".staff-chart"));
      }
      if(info) {
        const indicator = getStaffRadar(info.type);
        const url = `/admin/evaluate-user?expand=radarMap&filter[wx_user_id]=${info.wx_user_id}`;
        this.$axios(url).then(res => {
          if(res.data.length > 0) {
            const user = res.data[0];
            if(user.radarMap) {
              const map = user.radarMap;
              const value = indicator.map(item => {
                let res = 80;
                const catScore = map[item.cat];
                if(catScore && catScore.score) {
                  res = Math.round(catScore.score.reduce((acc, val) => acc + val) / catScore.score.length);
                }
                return res;
              });
              chartOption.series[0].data[0].value = value;
            }
          }
          chartOption.radar.indicator = indicator;
          this.chart.setOption(chartOption);
        });
      }
    }
  }
}
</script>

<style scoped lang="less">
  .staff-brief {
    display: flex;
    align-items: center;
    padding: 16px 0;
    .staff-info-reversed {
      flex-direction: row-reverse;
    }
  }
  .staff-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
    border-radius: 4px;
    background-color: #bbb;
    font-size: 32px;
    color: #fff;
  }
  .staff-info {
    flex: 1;
    min-width: 0;
    padding: 0 16px;
    color: @text-color-secondary;
  }
  .staff-name {
    margin-bottom: 8px;
    font-weight: bold;
    font-size: 24px;
    color: #333333;
  }
  .staff-brief-item {
    padding: 6px 0;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .staff-sex {
    color: @primary-color;
  }
  .staff-other {
    margin-top: 12px;
  }
  .staff-other-item {
    width: 240px;
  }
  .staff-chart {
    margin-left: 40px;
    width: 240px;
    height: 160px;
  }
</style>
