<template>
  <div class="data-content" :key="key" v-if="type && key">
    <a-card :bordered="false" title="设备数据">
      <router-link slot="extra" :to="{ name: `Safe${jumpKey}List` }" class="card-extra">
        查看更多<a-icon type="right" />
      </router-link>
      <div class="piece-area-ctx flex-box align-center justify-between">
        <div class="piece-view">
          <div class="piece-view-value">{{ info.total || 0 }}</div>
          <div class="piece-view-title">{{ typeText }}设备总数</div>
        </div>
        <div class="piece-view">
          <div class="piece-view-value">{{ info.online_count || 0 }}</div>
          <div class="piece-view-title">设备在线</div>
        </div>
        <div class="piece-view">
          <div class="piece-view-value">{{ info.online_rate || 0 }}</div>
          <div class="piece-view-title">设备在线率</div>
        </div>
      </div>
    </a-card>
    <a-card :bordered="false" class="data-piece">
      <div slot="title" class="flex-box align-center">
        <span>预警分析</span>
        <div class="select-box flex-box">
          <div
            class="option-item"
            :class="{ active: option.value == analysisType }"
            v-for="option in ymOptions"
            :key="option.value"
            @click="analysisSelect(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
      <router-link slot="extra" :to="{ name: `Safe${jumpKey}Record` }" class="card-extra">
        查看详情<a-icon type="right" />
      </router-link>
      <div class="flex-box">
        <chart :option="levelOption" class="chart-box flex-grow" :key="`${key}-level-chart`" />
        <chart :option="countOption" class="chart-box flex-grow" />
      </div>
    </a-card>
    <a-card :bordered="false" class="data-piece">
      <div slot="title" class="flex-box align-center">
        <span>预警事件处理分析</span>
        <div class="select-box flex-box">
          <div
            class="option-item"
            :class="{ active: option.value == workType }"
            v-for="option in ymOptions"
            :key="option.value"
            @click="workSelect(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
      <router-link slot="extra" :to="{ name: `Safe${jumpKey}Work` }" class="card-extra">
        查看详情<a-icon type="right" />
      </router-link>
      <div class="piece-info-box flex-box">
        <div class="piece-info flex-grow flex-box">
          <div class="piece-info-label">待处理</div>
          <div class="piece-info-value">{{ workInfo.pedding || 0 }}</div>
        </div>
        <div class="piece-info flex-grow flex-box">
          <div class="piece-info-label">处理中</div>
          <div class="piece-info-value">{{ workInfo.processimg || 0 }}</div>
        </div>
        <div class="piece-info flex-grow flex-box">
          <div class="piece-info-label">已处理</div>
          <div class="piece-info-value">{{ workInfo.processed || 0 }}</div>
        </div>
        <div class="piece-info flex-grow flex-box">
          <div class="piece-info-label">总处理时长</div>
          <div class="piece-info-value">{{ workInfo.totalTime || 0 }}</div>
        </div>
        <div class="piece-info flex-grow flex-box">
          <div class="piece-info-label">平均处理时长</div>
          <div class="piece-info-value">{{ workInfo.averageTime || 0 }}</div>
        </div>
      </div>
      <chart :option="workOption" class="chart-box flex-grow" />
    </a-card>
  </div>
</template>

<script>
import {
  ymOptions,
  getLevalPieChartOption,
  getCountLineChartOption,
  getWorkBarChartOption,
  getCountLiquidFillChartOption,
} from "../../common/constant/safe";
import { getTimeText } from "../../common/js/tool";
import { deviceOptions, moduleOptions } from "../../common/constant/safe";

export default {
  name: "SafeData",
  data() {
    return {
      info: {},
      ymOptions,
      analysisType: 1,
      workType: 1,
      workInfo: {},
      levelOption: {},
      countOption: {},
      workOption: {},
    };
  },
  computed: {
    key() {
      return this.$route.meta.key;
    },
    jumpKey() {
      let key = this.key;
      let jumpKey = key.charAt(0).toUpperCase() + key.slice(1);
      return jumpKey;
    },
    type() {
      let key = this.$route.meta.key;
      let type = moduleOptions.find((item) => item.key == key)?.value;
      return type;
    },
    typeText() {
      return deviceOptions.find((item) => item.value == this.type)?.label;
    },
  },
  watch: {
    analysisType() {
      this.getData();
    },
    workType() {
      this.getData();
    },
    key() {
      this.getData();
    },
  },
  created() {
    this.getData();
  },
  methods: {
    // 获取设备数据
    getData() {
      let params = {
        type: this.type,
      };
      if (this.analysisType == 1) {
        params.month = 1;
      } else {
        params.year = 1;
      }
      if (this.workType == 1) {
        params.event_month = 1;
      } else {
        params.event_year = 1;
      }
      this.$axios({
        url: "/admin/security-work-order/statistics",
        method: "GET",
        params,
      }).then((res) => {
        if (res.error == 0) {
          let data = res.data || {};
          this.info = {
            total: data.device_total || 0,
            online_count: data.device_online || 0,
            online_rate: (data.device_rate || 0) + "%",
          };
          let alarm_event = data?.alarm_event || {};
          this.workInfo = {
            pedding: alarm_event.pend_process,
            processimg: alarm_event.processing,
            processed: alarm_event.processed,
            totalTime: getTimeText(alarm_event.total_duration || 0),
            averageTime: getTimeText(alarm_event.average_duration || 0),
          };
          let alarm_analysis = data?.alarm_analysis || {};
          let lineData = alarm_analysis?.line || [];
          let histogram = alarm_event?.histogram || [];
          this.getLevelChart(alarm_analysis);
          this.getAnalysisChart(lineData);
          this.getWorkChart(histogram);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 预警等级
    getLevelChart(alarm_analysis) {
      let data = [
        {
          name: "一级预警",
          value: alarm_analysis.one || 0,
        },
        {
          name: "二级预警",
          value: alarm_analysis.two || 0,
        },
        {
          name: "三级预警",
          value: alarm_analysis.three || 0,
        },
        {
          name: "四级预警",
          value: alarm_analysis.four || 0,
        },
      ];
      if (["smoke", "monitor", "plaque", "massif"].indexOf(this.key) > -1) {
        // 只有一级
        data.splice(1);
        this.levelOption = getCountLiquidFillChartOption(data);
      } else {
        this.levelOption = getLevalPieChartOption(data);
      }
    },
    getDate(date, type) {
      if (type == 1) {
        let dateArr = date?.split("-");
        dateArr.shift();
        date = dateArr.join("-");
      }
      return date;
    },
    // 预警次数分析
    getAnalysisChart(lineData) {
      const data = lineData?.map((item) => {
        let date = this.getDate(item.date, this.analysisType);
        return {
          date,
          count: item.alarm_num,
        };
      });
      this.countOption = getCountLineChartOption(data);
    },
    // 预警事件处理分析
    getWorkChart(histogram) {
      const data = histogram?.map((item) => {
        let date = this.getDate(item.date, this.workType);
        return {
          date,
          processed: item.processed,
          untreated: item.pend_process,
        };
      });
      this.workOption = getWorkBarChartOption(data);
    },
    analysisSelect(option) {
      this.analysisType = option.value;
    },
    workSelect(option) {
      this.workType = option.value;
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../../common/less/safe.less");
</style>
