<template>
    <div class="content md ass-score piece-box">
        <template v-if="info">
            <div class="ant-descriptions-title">基本信息</div>
            <div class="ass-user-info" style="margin-bottom: 20px">
                <staff-brief :info="user" v-if="user"></staff-brief>
                <div class="home-info-tip" v-else-if="user === false">
                    <open-data type="userName" :openid="info.user_id"></open-data>
                    <span>的资料尚未完善</span>
                </div>
            </div>
            <div class="ant-descriptions-title flex-box align-center justify-between">
                <div>评价详情</div>
                <div class="ass-score-total" v-if="info.status == 1">总成绩：{{info.score}}分</div>
            </div>
            <div class="ass-detail-piece" style="margin-bottom: 20px">
                <a-table
                  :columns="columns"
                  :data-source="list"
                  :pagination="false"
                  :row-key="record => record.key || record.id"
                  bordered>
                </a-table>
            </div>
            <div class="ant-descriptions-title">个人成长趋势</div>
            <div class="ass-grow-chart" id="grow_chart">
                <a-empty :image="simpleImage" description="暂无数据" v-if="nt"/>
            </div>
        </template>
        <div class="piece-loading" v-else>
            <a-icon type="loading" />
        </div>
    </div>
</template>

<script>
    import staffBrief from "../../components/staff-brief";
    import {dealStaff} from "../../common/hr/book";
    import trend from "../../common/hr/trend";
    import {getDate, isEmpty} from "../../common/js/tool";
    import * as echarts from "echarts";
    import { Empty } from 'ant-design-vue';
    import {
        getAssNormList,
        getAssRuleType,
        getSysRuleText,
        getSystemAssTypeText,
        // scoreRender
    } from "../../common/hr/ass";

    export default {
        name: "AssPersonDetail",
        components: {
            staffBrief
        },
        data() {
            return {
                getSysRuleText,
                getAssRuleType,
                getSystemAssTypeText,
                info: null,
                user: null,
                list: [],
                nt: false,
                simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
                columns: [
                    {title: '序号', dataIndex: 'index', width: 80, customRender: (text, record, index) => index + 1},
                    { title: '评价指标', dataIndex: 'name', width: 160 },
                    {
                        title: '指标说明',
                        dataIndex: 'normList',
                        customRender: (value, row) => row.type == 1 ? row.detail : (value && value.length > 0 ?
                          <div>{ value.map((item, i) => <div>{getSysRuleText(item, row.assType, i)}</div>) }</div> : "无")
                    },
                    { title: '单项总分', dataIndex: 'percent', width: 120, customRender: value => value + '分'},
                    // { title: '自评', dataIndex: 'selfScore', customRender: scoreRender},
                    // { title: '主管评价', dataIndex: 'directorScore', customRender: scoreRender},
                    { title: '实际得分', dataIndex: 'score', width: 100, customRender: value => !isEmpty(value) ? `${value}分` : "未打分" },
                ],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                }
            }
        },
        created() {
            this.getDetail();
        },
        methods: {
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
            },
            getDetail() {
                let url = `/admin/evaluate-item/${this.$route.params.id}?expand=message`;
                this.$axios(url).then(this.dealInfo);
            },
            dealInfo(info) {
                this.list = getAssNormList(info);
                this.info = info;
                this.getAssUser();
                this.$nextTick(() => {
                    this.getUserAssList();
                })
            },
            getAssUser() {
                let wxId = this.info.user_id
                this.$axios(`/admin/evaluate-user?filter[wx_user_id]=${wxId}`).then(res => {
                    if(res.data && res.data.length > 0) {
                        let staff = res.data[0];
                        this.user = dealStaff(staff);
                    } else {
                        this.user = false;
                    }
                })
            },
            getUserAssList() {
                let wxId = this.info.user_id;
                this.$axios(`/admin/evaluate-item?expand=message&pageSize=100&filter[user_id]=${wxId}&filter[status]=1`).then(res => {
                    let list = res.data;
                    this.setTrendData(list);
                });
            },
            setTrendData(list) {
                if(list && list.length > 0) {
                    let dateList = [], scoreList = [];
                    list.forEach(item => {
                        dateList.push(getDate(item.begin_time).pattern("yyyy-MM-dd"));
                        scoreList.push(item.score);
                    });
                    trend.xAxis.data = dateList;
                    trend.series[0].data = scoreList;
                    this.chart = echarts.init(this.$el.querySelector("#grow_chart"));
                    this.chart.setOption(trend);
                } else {
                    this.nt = true;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .ass-score {
        overflow: auto;
    }
    .ass-user-info {
        padding: 0 24px;
        border: var(--border);
        border-radius: 8px;
    }
    .home-info-tip {
        padding: 12px 0;
    }
    .ass-score-table {
        width: 100%;
        border: var(--border);
        text-align: center;
        tr, td, th {
            border: var(--border);
        }
        th {
            white-space: nowrap;
        }
        td, th {
            padding: 8px 12px;
        }
    }
    .ass-grow-chart {
        height: 300px;
    }
    .ass-score-total {
        color: @error-color;
    }
</style>
