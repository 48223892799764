<template>
    <div class="content md ass-program-area piece-box">
        <template v-if="info">
            <ass-list-info :info="info"></ass-list-info>
            <div class="ass-detail-piece" style="margin-top: 16px">
                <div class="ant-descriptions-title">评价统计</div>
                <div class="flex-box ass-count">
                    <div class="ass-count-piece">
                        <div class="ass-count-title">评价分数排名TOP3</div>
                        <div class="ass-top-list">
                            <a-table
                              ref="assTable"
                              :columns="topColumns"
                              row-key="id"
                              :data-source="topList"
                              :pagination="false">
                            </a-table>
                        </div>
                    </div>
                    <div class="ass-count-piece">
                        <div class="ass-count-title">评价分数分布</div>
                        <div class="ass-chart" id="ass_chart"></div>
                    </div>
                </div>
            </div>
            <div class="ass-detail-piece" style="margin-top: 16px">
                <div class="ant-descriptions-title">评价统计</div>
                <a-table
                  ref="assTable"
                  :columns="columns"
                  row-key="id"
                  :data-source="list"
                  :pagination="pagination"
                  bordered
                  @change="handleTableChange">
                    <template v-slot:action="text, record">
                        <div class="row-btn">
                            <a class="txt-btn" @click.stop="showDetail(record)">查看</a>
                            <a class="txt-btn" @click.stop="editScore(record)" v-if="user && user.role == 1">修改</a>
                        </div>
                    </template>
                </a-table>
            </div>
        </template>
        <div class="piece-loading" v-else>
            <a-icon type="loading" />
        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    import score from "../../common/hr/score";
    import {getAssListStatusText, getAssTypeName} from "../../common/hr/ass";
    import {clone} from "../../common/js/tool";
    import {getStorageInfo} from "../../common/js/storage";
    import AssListInfo from "../../layouts/AssListInfo";

    export default {
        name: "AssProgramDetail",
        components: {
            AssListInfo
        },
        data() {
            return {
                info: null,
                list: [],
                topList: [],
                pagination: {
                    total: 0,
                    current: 1,
                    pageSize: 10,
                    showSizeChanger: true
                },
                topColumns: [
                    { title: '序号', dataIndex: 'index', customRender: (value, row, index) => index + 1 },
                    {
                        title: '姓名',
                        dataIndex: 'user_id',
                        customRender: text => <open-data type="userName" openid={text} />
                    },
                    {title: '分数', dataIndex: 'score'},
                ],
                columns: [
                    {
                        title: '姓名',
                        dataIndex: 'user_id',
                        customRender: text => <open-data type="userName" openid={text} />
                    },
                    {title: '评价名称', dataIndex: 'assName'},
                    {title: '评价分数', dataIndex: 'score'},
                    {title: '操作', key: 'action', fixed: 'right', width: 120, scopedSlots: { customRender: 'action'}}
                ],
                user: getStorageInfo()
            }
        },
        created() {
            this.getDetail();
        },
        methods: {
            showDetail(record) {
                this.$router.push({name: "AssPersonDetail", params: {id: record.id}});
            },
            editScore(record) {
                let score = record.score;
                this.$confirm({
                    title: '修改分数',
                    content: <a-input v-model={score} placeholder="请输入分数" style="width: 100%" />,
                    onOk: () => {
                        let error = "";
                        if(score < 0) {
                            error = "分数必须大于0";
                        }
                        if(error) {
                            this.$message.warning(error)
                        } else {
                            this.$axios({
                                url: "/admin/evaluate-item/" + record.id,
                                method: "PATCH",
                                data: {
                                    score
                                },
                                noTempleFilter: true
                            }).then(()=> {
                                this.$message.success("已修改");
                                this.$set(record, 'score', score);
                            });
                        }
                    },
                });
            },
            getDetail() {
                let id = this.$route.params.id;
                let url = `/admin/evaluate-list/${id}?expand=item`;
                this.$axios(url).then(res => {
                    res.baseInfo = [
                        {label: '评价名称', value: res.title},
                        {label: '状态', value:getAssListStatusText(res.status)},
                        {label: '评价类型', value: res.program ? getAssTypeName(res.program.type) : '-'},
                        {label: '开始时间', value: res.begin_time},
                        {label: '截止时间', value: res.end_time},
                    ];
                    let list = [...res.item];
                    if(list) {
                        list.sort((a, b) => {
                            return b.score - a.score;
                        });
                        let topList = list.slice(0, 3);
                        this.topList = topList;
                    }
                    this.info = res;
                    this.$nextTick(() => {
                        this.initChart(list);
                    })
                });
            },
            handleTableChange(pagination) {
                let pager = { ...this.pagination };
                pager.current = pagination.current;
                pager.pageSize = pagination.pageSize;
                this.pagination = pager;
            },
            initChart(list) {
                if(list && list.length > 0) {
                    let options = clone(score);
                    let data = options.series[0].data;
                    list.forEach(item => {
                        item.assName = this.info.title;
                        let score = item.score;
                        if(score >= 90) {
                            data[0].value += 1;
                        } else if(score >= 80 && score < 90) {
                            data[1].value += 1;
                        } else if(score >= 70 && score < 80) {
                            data[2].value += 1;
                        } else {
                            data[3].value += 1;
                        }
                    });
                    this.chart = echarts.init(this.$el.querySelector("#ass_chart"));
                    this.chart.setOption(options);
                    this.list = list;
                }
            }
        }
    }
</script>

<style scoped lang="less">
    .ass-program-area {
        overflow: auto;
    }
    .ass-detail-item {
        display: flex;
        padding: 4px 0;
        line-height: 1.5;
    }
    .detail-piece-box {
        padding: 0 24px;
    }
    .ass-detail-label {
        flex-shrink: 0;
        width: 100px;
    }
    .ass-top {
        padding: 16px 24px;
        font-size: 16px;
    }
    .ass-top-item {
        display: flex;
        align-items: center;
    }
    .ass-top-user {
        margin: 0 24px;
    }
    .ass-chart {
        margin-top: 16px;
        height: 216px;
    }
    .ass-count {
        padding: 0 48px;
    }
    .ass-count-piece {
        flex: 1;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid @border-color-base;
        min-width: 0;
        &:not(:first-of-type) {
            margin-left: 64px;
        }
    }
    .ass-count-title {
        color: @text-color;
    }
    .ass-top-list {
        margin-top: 16px;
    }
</style>
