export default {
    legend: {
        top: 'middle',
        right: 24,
        orient: 'vertical',
        data: ['90-100分', '80-89分', '70-79分', '70分以下']
    },
    series: [
        {
            type: 'pie',
            name: '分数',
            radius: [60, 80],
            label: {
                position: 'inside',
                formatter: '{d}%'
            },
            center: ['30%', '50%'],
            data: [
                {value: 0, name: '90-100分'},
                {value: 0, name: '80-89分'},
                {value: 0, name: '70-79分'},
                {value: 0, name: '70分以下'},
            ]
        },
    ]
}
